<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        Fill in the blanks (dropdowns) in the flow chart below that describes the procedure for
        <b>Elimination lab - Part B1 and B2</b>. See the cartoon depiction of the experimental set
        up below for any terms you are not familiar with.
      </p>

      <p class="mb-5 pl-8">
        <v-img src="/img/assignments/UCI_A3_Q10.png" max-width="457px" />
      </p>

      <p class="mb-5">
        1. In a 10 mL round bottom flask equipped with a water cooled condenser place
        <v-select
          v-model="inputs.input1"
          style="display: inline-block; width: 12em"
          class="mb-n4 ml-1"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        of 1 M
        <stemble-latex content="$tert\ce{-BuOK}\text{.}$" />
        Add a
        <v-select
          v-model="inputs.input2"
          style="display: inline-block; width: 12em"
          class="mb-n4 ml-1"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        and connect the condenser to the gas collection assembly.
      </p>

      <p class="mb-5">
        2. Use a
        <v-select
          v-model="inputs.input3"
          style="display: inline-block; width: 12em"
          class="mb-n4 ml-1"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        to add 0.5 mL of 2-bromobutane to the assembled apparatus. Heat the mixture in a preheated
        <v-select
          v-model="inputs.input4"
          style="display: inline-block; width: 12em"
          class="mb-n4 ml-1"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>

      <p class="mb-5">
        3. Keep heating until no gas bubbles escape from the bottom of the collection tube.
      </p>

      <p class="mb-5">
        4. Use the technique outlined in Part A for removing the preparing the sample for submission
        to your TA.
      </p>

      <p class="mb-5">
        5. Determine the relative amount of the
        <v-select
          v-model="inputs.input5"
          style="display: inline-block; width: 12em"
          class="mb-n4 ml-1"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        components, just as you did in Part A.
      </p>

      <p class="mb-5">
        6.
        <v-select
          v-model="inputs.input6"
          style="display: inline-block; width: 12em"
          class="mb-n4 ml-1"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
        will elute first off of the column.
      </p>

      <p>
        7. Repeat the preceding steps using the same apparatus as in the image, but with
        <v-select
          v-model="inputs.input7"
          style="display: inline-block; width: 12em"
          class="mb-n4 ml-1"
          dense
          :items="optionsShuffled"
          item-text="text"
          item-value="value"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';
import {seededShuffle} from '@/courses/utils/seededShuffle';
import ChemicalLatex from '@/tasks/components/displayers/ChemicalLatex.vue';

export default {
  name: 'ChemUCI51lbA3_Q11',
  components: {ChemicalLatex, StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
      },
      options: [
        {text: '5 mL', value: '5ml'},
        {text: 'boiling chip', value: 'boilingChip'},
        {text: 'syringe', value: 'syringe'},
        {text: 'sand bath', value: 'sandBath'},
        {text: 'three', value: 'three'},
        {text: '1-butene', value: '1butene'},
        {text: '1-bromobutane', value: '1bromobutane'},
      ],
    };
  },
  computed: {
    seed() {
      return this.$gate.user?.id || 1;
    },
    optionsShuffled() {
      return seededShuffle(this.options, this.seed);
    },
  },
};
</script>
