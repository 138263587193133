var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.submitResponse.apply(null, arguments)}}},[_c('p',{staticClass:"mb-5"},[_vm._v(" Fill in the blanks (dropdowns) in the flow chart below that describes the procedure for "),_c('b',[_vm._v("Elimination lab - Part B1 and B2")]),_vm._v(". See the cartoon depiction of the experimental set up below for any terms you are not familiar with. ")]),_c('p',{staticClass:"mb-5 pl-8"},[_c('v-img',{attrs:{"src":"/img/assignments/UCI_A3_Q10.png","max-width":"457px"}})],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 1. In a 10 mL round bottom flask equipped with a water cooled condenser place "),_c('v-select',{staticClass:"mb-n4 ml-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input1),callback:function ($$v) {_vm.$set(_vm.inputs, "input1", $$v)},expression:"inputs.input1"}}),_vm._v(" of 1 M "),_c('stemble-latex',{attrs:{"content":"$tert\\ce{-BuOK}\\text{.}$"}}),_vm._v(" Add a "),_c('v-select',{staticClass:"mb-n4 ml-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input2),callback:function ($$v) {_vm.$set(_vm.inputs, "input2", $$v)},expression:"inputs.input2"}}),_vm._v(" and connect the condenser to the gas collection assembly. ")],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 2. Use a "),_c('v-select',{staticClass:"mb-n4 ml-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input3),callback:function ($$v) {_vm.$set(_vm.inputs, "input3", $$v)},expression:"inputs.input3"}}),_vm._v(" to add 0.5 mL of 2-bromobutane to the assembled apparatus. Heat the mixture in a preheated "),_c('v-select',{staticClass:"mb-n4 ml-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input4),callback:function ($$v) {_vm.$set(_vm.inputs, "input4", $$v)},expression:"inputs.input4"}})],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 3. Keep heating until no gas bubbles escape from the bottom of the collection tube. ")]),_c('p',{staticClass:"mb-5"},[_vm._v(" 4. Use the technique outlined in Part A for removing the preparing the sample for submission to your TA. ")]),_c('p',{staticClass:"mb-5"},[_vm._v(" 5. Determine the relative amount of the "),_c('v-select',{staticClass:"mb-n4 ml-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input5),callback:function ($$v) {_vm.$set(_vm.inputs, "input5", $$v)},expression:"inputs.input5"}}),_vm._v(" components, just as you did in Part A. ")],1),_c('p',{staticClass:"mb-5"},[_vm._v(" 6. "),_c('v-select',{staticClass:"mb-n4 ml-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input6),callback:function ($$v) {_vm.$set(_vm.inputs, "input6", $$v)},expression:"inputs.input6"}}),_vm._v(" will elute first off of the column. ")],1),_c('p',[_vm._v(" 7. Repeat the preceding steps using the same apparatus as in the image, but with "),_c('v-select',{staticClass:"mb-n4 ml-1",staticStyle:{"display":"inline-block","width":"12em"},attrs:{"dense":"","items":_vm.optionsShuffled,"item-text":"text","item-value":"value"},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('stemble-latex',{staticClass:"no-text-transform",attrs:{"content":item.text}})]}}]),model:{value:(_vm.inputs.input7),callback:function ($$v) {_vm.$set(_vm.inputs, "input7", $$v)},expression:"inputs.input7"}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }